/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DateTimeRangeDto interface.
 */
export function instanceOfDateTimeRangeDto(value) {
    return true;
}
export function DateTimeRangeDtoFromJSON(json) {
    return DateTimeRangeDtoFromJSONTyped(json, false);
}
export function DateTimeRangeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
    };
}
export function DateTimeRangeDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'start': value['start'] == null ? undefined : (value['start'].toISOString()),
        'end': value['end'] == null ? undefined : (value['end'].toISOString()),
    };
}
