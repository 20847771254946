/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatMessageDtoFromJSON, ChatMessageDtoToJSON, } from './ChatMessageDto.js';
/**
 * Check if a given object implements the GetConferenceChatConferenceChatDto interface.
 */
export function instanceOfGetConferenceChatConferenceChatDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('visitorName' in value) || value['visitorName'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('lastMessageAt' in value) || value['lastMessageAt'] === undefined)
        return false;
    if (!('lastMessage' in value) || value['lastMessage'] === undefined)
        return false;
    if (!('messages' in value) || value['messages'] === undefined)
        return false;
    return true;
}
export function GetConferenceChatConferenceChatDtoFromJSON(json) {
    return GetConferenceChatConferenceChatDtoFromJSONTyped(json, false);
}
export function GetConferenceChatConferenceChatDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'visitorName': json['visitorName'],
        'jobId': json['jobId'] == null ? undefined : json['jobId'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'createdAt': (new Date(json['createdAt'])),
        'lastMessageAt': (new Date(json['lastMessageAt'])),
        'lastMessage': json['lastMessage'],
        'messages': (json['messages'].map(ChatMessageDtoFromJSON)),
    };
}
export function GetConferenceChatConferenceChatDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'visitorName': value['visitorName'],
        'jobId': value['jobId'],
        'jobTitle': value['jobTitle'],
        'createdAt': ((value['createdAt']).toISOString()),
        'lastMessageAt': ((value['lastMessageAt']).toISOString()),
        'lastMessage': value['lastMessage'],
        'messages': (value['messages'].map(ChatMessageDtoToJSON)),
    };
}
