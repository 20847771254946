import _definePage_default_0 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/AuthInfoState.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/Employee.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/Login.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/ResetPassword.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/auth.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/company/index.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/conference/chats/index.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/settings.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/create.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/matches/index.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/billing.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/company.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/register.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding/register.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding/introduction.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/home/runner/work/frontend-employer/frontend-employer/src/pages/upgrade.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '/auth-info',
        name: '/auth-state',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/AuthInfoState.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
  _mergeRouteRecord(
      {
        path: '/employee',
        name: '/employee',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/Employee.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: '/login',
        name: '/login',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/Login.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: '/reset-password',
        name: '/reset-password',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/ResetPassword.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  _definePage_default_6
  ),
  {
    path: '/company',
    /* internal name: '/company' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/company/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/company/index.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/conference',
    /* internal name: '/conference' */
    /* no component */
    children: [
      {
        path: 'chats',
        /* internal name: '/conference/chats' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '/bezoekers',
            name: '/conference/chats/',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/conference/chats/index.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      }
    ],
  },
  {
    path: '/Error',
    name: '/Error',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/Error.vue'),
    /* no children */
    meta: {
      "layout": "error"
    }
  },
  _mergeRouteRecord(
  {
    path: '/instellingen',
    name: '/settings',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings.vue'),
    children: [
      {
        path: '/instellingen/account',
        name: '/settings/Account',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/Account.vue'),
        /* no children */
        meta: {
          "requiredAuthProvider": "firebase"
        }
      },
      {
        path: '/instellingen/algemene-voorwaarden',
        name: '/settings/TermsAndConditions',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/TermsAndConditions.vue'),
        /* no children */
      },
      {
        path: '/instellingen/gebruikersprofiel',
        name: '/settings/UserProfile',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/UserProfile.vue'),
        /* no children */
      },
      {
        path: '/instellingen/standaard-berichten',
        name: '/settings/DefaultMessages',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/DefaultMessages.vue'),
        /* no children */
      },
      {
        path: '/instellingen/teamgenoten',
        name: '/settings/Teammates',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/Teammates.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_9
  ),
  {
    path: '/invites',
    /* internal name: '/invites' */
    /* no component */
    children: [
      {
        path: '',
        name: '/invites/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/invites/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/job-applications',
    /* internal name: '/job-applications' */
    /* no component */
    children: [
      {
        path: '',
        name: '/job-applications/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/job-applications/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "JobApplication"
        }
      },
      {
        path: ':jobApplicationId',
        name: '/job-applications/[jobApplicationId]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/job-applications/[jobApplicationId].vue'),
        /* no children */
        meta: {
          "permissionResource": "JobApplication"
        }
      }
    ],
  },
  {
    path: '/jobs',
    /* internal name: '/jobs' */
    /* no component */
    children: [
      {
        path: '',
        name: '/jobs/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job"
        }
      },
      {
        path: ':jobId',
        name: '/jobs/[jobId]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].vue'),
        children: [
          {
            path: 'applicants',
            name: '/jobs/[jobId]/applicants',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/applicants.vue'),
            /* no children */
          },
          {
            path: 'matches',
            name: '/jobs/[jobId]/matches',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/matches.vue'),
            /* no children */
          },
          {
            path: 'rejected-applicants',
            name: '/jobs/[jobId]/rejected-applicants',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/rejected-applicants.vue'),
            /* no children */
          }
        ],
      },
      {
        path: ':jobId/duplicate',
        name: '/jobs/[jobId].duplicate',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].duplicate.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job",
          "permissionLevel": "Write"
        }
      },
      {
        path: ':jobId/update',
        name: '/jobs/[jobId].update',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].update.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job",
          "permissionLevel": "Write"
        }
      },
  _mergeRouteRecord(
      {
        path: 'create',
        name: '/jobs/create',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/create.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/matches',
    /* internal name: '/matches' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/matches/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/matches/index.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
      {
        path: ':id',
        name: '/matches/[id]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/matches/[id].vue'),
        /* no children */
        meta: {
          "permissionResource": "Match"
        }
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'billing',
        name: '/onboarding/billing',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/billing.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
  _mergeRouteRecord(
      {
        path: 'company',
        name: '/onboarding/company',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/company.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'register',
        name: '/onboarding/register',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/register.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
      {
        path: 'subscription',
        name: '/onboarding/subscription',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/subscription.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_15
  ),
  _mergeRouteRecord(
  {
    path: '/promotiedagen/onboarding',
    name: '/conference/onboarding',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'registreren',
        name: '/conference.onboarding/register',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding/register.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'welkom',
        name: '/conference.onboarding/introduction',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/conference.onboarding/introduction.vue'),
        /* no children */
      },
  _definePage_default_17
  )
    ],
  },
  _definePage_default_18
  ),
  _mergeRouteRecord(
  {
    path: '/upgrade',
    name: '/upgrade',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/upgrade.vue'),
    children: [
      {
        path: 'account',
        name: '/upgrade/account',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/upgrade/account.vue'),
        /* no children */
      },
      {
        path: 'billing',
        name: '/upgrade/billing',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/upgrade/billing.vue'),
        /* no children */
      },
      {
        path: 'subscription',
        name: '/upgrade/subscription',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/upgrade/subscription.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_19
  ),
  {
    path: '/verbruik',
    name: '/usage',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage.vue'),
    children: [
      {
        path: '/verbruik/abonnement',
        name: '/usage/Subscription',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Subscription.vue'),
        /* no children */
      },
      {
        path: '/verbruik/crediet',
        name: '/usage/Credits',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Credits.vue'),
        /* no children */
      },
      {
        path: '/verbruik/facturen',
        name: '/usage/Invoices',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Invoices.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

