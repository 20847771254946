/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConferenceToCompanyDtoFromJSON, ConferenceToCompanyDtoToJSON, } from './ConferenceToCompanyDto.js';
/**
 * Check if a given object implements the GetConferenceInfoResult interface.
 */
export function instanceOfGetConferenceInfoResult(value) {
    return true;
}
export function GetConferenceInfoResultFromJSON(json) {
    return GetConferenceInfoResultFromJSONTyped(json, false);
}
export function GetConferenceInfoResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'conference': json['conference'] == null ? undefined : ConferenceToCompanyDtoFromJSON(json['conference']),
    };
}
export function GetConferenceInfoResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'conference': ConferenceToCompanyDtoToJSON(value['conference']),
    };
}
