/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListConferenceChatsConferenceChatDtoFromJSON, ListConferenceChatsConferenceChatDtoToJSON, } from './ListConferenceChatsConferenceChatDto.js';
/**
 * Check if a given object implements the ListConferenceChatsResult interface.
 */
export function instanceOfListConferenceChatsResult(value) {
    if (!('chats' in value) || value['chats'] === undefined)
        return false;
    return true;
}
export function ListConferenceChatsResultFromJSON(json) {
    return ListConferenceChatsResultFromJSONTyped(json, false);
}
export function ListConferenceChatsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'chats': (json['chats'].map(ListConferenceChatsConferenceChatDtoFromJSON)),
    };
}
export function ListConferenceChatsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'chats': (value['chats'].map(ListConferenceChatsConferenceChatDtoToJSON)),
    };
}
