/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EmployerInviteType = {
    Internal: 'Internal',
    Referral: 'Referral',
    Teammate: 'Teammate',
    Conference: 'Conference'
};
export function instanceOfEmployerInviteType(value) {
    for (const key in EmployerInviteType) {
        if (Object.prototype.hasOwnProperty.call(EmployerInviteType, key)) {
            if (EmployerInviteType[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function EmployerInviteTypeFromJSON(json) {
    return EmployerInviteTypeFromJSONTyped(json, false);
}
export function EmployerInviteTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EmployerInviteTypeToJSON(value) {
    return value;
}
