/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConvertLeadCompanyToCustomerInvoiceDtoFromJSON, ConvertLeadCompanyToCustomerInvoiceDtoToJSON, } from './ConvertLeadCompanyToCustomerInvoiceDto.js';
/**
 * Check if a given object implements the ConvertLeadCompanyToCustomerCommandDto interface.
 */
export function instanceOfConvertLeadCompanyToCustomerCommandDto(value) {
    if (!('hasAcceptedTermsAndConditions' in value) || value['hasAcceptedTermsAndConditions'] === undefined)
        return false;
    if (!('invoice' in value) || value['invoice'] === undefined)
        return false;
    return true;
}
export function ConvertLeadCompanyToCustomerCommandDtoFromJSON(json) {
    return ConvertLeadCompanyToCustomerCommandDtoFromJSONTyped(json, false);
}
export function ConvertLeadCompanyToCustomerCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'subscriptionId': json['subscriptionId'] == null ? undefined : json['subscriptionId'],
        'creditPackageId': json['creditPackageId'] == null ? undefined : json['creditPackageId'],
        'hasAcceptedTermsAndConditions': json['hasAcceptedTermsAndConditions'],
        'invoice': ConvertLeadCompanyToCustomerInvoiceDtoFromJSON(json['invoice']),
    };
}
export function ConvertLeadCompanyToCustomerCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'subscriptionId': value['subscriptionId'],
        'creditPackageId': value['creditPackageId'],
        'hasAcceptedTermsAndConditions': value['hasAcceptedTermsAndConditions'],
        'invoice': ConvertLeadCompanyToCustomerInvoiceDtoToJSON(value['invoice']),
    };
}
