/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateConferenceEmployerAccountResult interface.
 */
export function instanceOfCreateConferenceEmployerAccountResult(value) {
    if (!('externalUserId' in value) || value['externalUserId'] === undefined)
        return false;
    if (!('token' in value) || value['token'] === undefined)
        return false;
    return true;
}
export function CreateConferenceEmployerAccountResultFromJSON(json) {
    return CreateConferenceEmployerAccountResultFromJSONTyped(json, false);
}
export function CreateConferenceEmployerAccountResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'externalUserId': json['externalUserId'],
        'token': json['token'],
    };
}
export function CreateConferenceEmployerAccountResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'companyId': value['companyId'],
        'externalUserId': value['externalUserId'],
        'token': value['token'],
    };
}
