<script setup lang="ts">
import { EmployerRole } from '@swipe4work/api-client-fetch'

definePage({
  name: '/upgrade',
  path: '/upgrade',
  meta: {
    allowedRoles: [EmployerRole.Lead],
    layout: 'upgrade',
  },
  redirect: '/upgrade/account',
})
</script>

<template>
  <router-view v-slot="{ Component }">
    <suspense>
      <component :is="Component" />
    </suspense>
  </router-view>
</template>
