<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'

import { PermissionResource } from '@swipe4work/api-client-fetch'
import { storeToRefs } from 'pinia'
import ConferenceChatList from './components/ConferenceChatList.vue'
import ConferenceChatListEmpty from './components/ConferenceChatListEmpty.vue'
import { useTopbarStore } from '@/components/app/topbar-store'
import { useEmployerApi } from '@/modules/http-clients'
import { useConferenceStore } from '@/store/conference'

definePage({
  path: '/bezoekers',
  meta: {
    permissionResource: PermissionResource.ConferenceSwipe,
  },
})
const t = useI18n()

const topbarStore = useTopbarStore()

topbarStore.setAndClear({
  breadcrumbs: [{ label: t.t('routes.conferenceChats') }],
})

const { conference } = storeToRefs(useConferenceStore())
const employerApi = useEmployerApi()

const { error, isPending, data } = useQuery({
  queryKey: ['conferene-chats'],
  // trigger the query when the conference is loaded
  enabled: conference.value !== null,
  queryFn: async () => {
    return employerApi.listConferenceChats(conference.value!.conferenceId)
  },
})
</script>

<template>
  <Skeleton v-if="isPending" />
  <template v-else-if="data">
    <ConferenceChatListEmpty v-if="!data.chats.length" />
    <ConferenceChatList v-else :conference-chats="data.chats" />
  </template>
  <template v-else-if="error">
    <div>{{ error.message }}</div>
  </template>
</template>
